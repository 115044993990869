// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./adminbetaregister.scss"></require>
  <section id="adminbetaregister" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateToRoute('adminhome')" src="${___HTML_LOADER_IMPORT_0___}">
      <h1>
        User list
      </h1>
    </div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>UserName</th>
            <th>Email</th>
            <th>Verified?</th>
            <th>Role</th>
            <th>Subscribed?</th>
          </tr>
        </thead>
        <tbody>
          <tr repeat.for="index of allUsers.docs">
            <td>\${index.displayName}</td>
            <td>\${index.email}</td>
            <td>\${index.verified}</td>
            <td>\${index.role}</td>
            <td>
              <p if.bind="index.isSubscribed === true">Yes</p>
              <p if.bind="index.isSubscribed === false">No</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <table>
      <tr>
        <th>UserName</th>
        <th>Email</th>
        <th>Role</th>
        <th>Subscribed?</th>
      </tr>
      <tr repeat.for="index of allUsers.docs">
        <td>\${index.displayName}</td>
        <td>\${index.email}</td>
        <td>\${index.role}</td>
        <td>
          <p if.bind="index.isSubscribed === true">Yes</p>
          <p if.bind="index.isSubscribed === false">No</p>
        </td>
      </tr>
    </table> -->
  </section>
</template>
`;
// Exports
export default code;